/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Message My Customer's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* You can add global styles to this file, and also import other style files */

// custom emijo styles
@use "emoji";

/*html,
body,
html * {
    font-family: 'Roboto', sans-serif !important;
}*/

/* Style applied to conversation */
span > p {
    font-weight: bold;
    margin-bottom: 5px;
}

/* Backdrop */
.mat-drawer-backdrop.mat-drawer-shown {
    background: transparent !important;
}

.button-small.mat-mdc-icon-button.mat-mdc-button-base {
    min-width: 30px;
    min-height: 30px;
    width: 30px !important;
    height: 30px;

    svg {
        width: 15px;
        height: 15px;
    }
}

.mat-mdc-menu-panel.mdc-menu-surface {
    min-width: 120px !important;
}

/* Tooltip */
.cdk-overlay-container, .cdk-global-overlay-wrapper {
    white-space: pre-line;
}

.mat-form-field-appearance-outline {

    .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
        border: none;
    }
}

/* Timepicker */
ngx-mat-timepicker-dialog {
    .mat-toolbar.timepicker-header {
        height: 98px;
    }
    .mat-mdc-dialog-content.mdc-dialog__content {
        margin-right: -24px;
        margin-left: -24px;
        margin-top: -24px;
        overflow: auto;
        padding: 0;
    }
    .timepicker-dial__control:focus, .timepicker-period__btn:focus {
        color: white;
        background: none;
    }
}

/* Popover calendar */
.mat-mdc-menu-panel.ngx-mat-popover {
    margin-left: 27px;

    .mat-mdc-menu-item {
        padding: 20px;
        cursor: default;
        &:hover:not([disabled]), &.cdk-focused {
            background: white;
        }
        
    }
}

/* Buttons */
.mdc-button.custom {
    width: 108px;
    border: 1px solid var(--fuse-primary2-700);
    font-weight: 500;
}
.mdc-button.mat-primary:disabled {
    --mdc-filled-button-disabled-container-color: var(--fuse-primary-500) !important;
    --mdc-filled-button-disabled-label-text-color: #000 !important;
    --fuse-text-disabled-rgb: #000 !important;
    opacity: 0.4 !important;
}
.mdc-button.mat-warn:disabled {
    border-color: var(--fuse-primary2-300) !important;
}

.mdc-switch__ripple {
    --mdc-switch-state-layer-size: 35px;
}

/* Mat expansion panel */
.mat-expansion-panel-header .mat-content {
    margin: 0;
    height: 100%;
}

.mat-expansion-panel {
    .mat-expansion-panel-body {
        padding: 0;
    }
}

/* Paginator */
.mat-mdc-paginator .mat-mdc-paginator-outer-container .mat-mdc-paginator-container {
    justify-content: flex-end;
}

/* General styles */

.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.create-button {
    width: 110px;
}

/* Navigation menu */

fuse-vertical-navigation .fuse-vertical-navigation-item-expanded .fuse-vertical-navigation-item-children .fuse-vertical-navigation-item-wrapper .fuse-vertical-navigation-item {
    padding-left: 53px;
}

.fuse-vertical-navigation-item-icon svg {
    width: 21px;
}

/* Icons */
.icon-whatsapp, .fa-whatsapp {
    color: #25D366;
}

.fa-comment-sms {
    color: black;
}

/* Chat channel group */
#channel-group-selector-panel {
    min-width: max-content;

    .mat-mdc-option:not(.mat-mdc-option-active) {
        padding-right: 54px;
    }
}

/* Dialog */
.mat-mdc-dialog-container {
    --mat-dialog-headline-padding: 0 18px 9px;

}

/* Open popup signin dialog */
.open-popup-dialog {
    --mdc-filled-button-container-color: #8271f5;
    --mdc-filled-button-label-text-color: white;
}

.mat-mdc-dialog-actions.mdc-dialog__actions {
    border-top: none;
}

/* Field errors */ 
.mat-mdc-form-field .mat-mdc-text-field-wrapper {
    max-height: 56px !important;

    &:has(.cdk-textarea-autosize) {
        max-height: 100% !important;
    }
}

.mat-mdc-form-field-subscript-wrapper:has(.mat-mdc-form-field-error) {
    padding-bottom: 10px;
}

/* Theme variables overrides */
.theme-default.light {
    --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, .42) !important;
    --mat-select-enabled-arrow-color: rgba(0, 0, 0, .54);
    --mat-optgroup-label-text-weight: bold;
}

.mat-drawer-container:has( > div.mat-drawer-shown) .mat-drawer-content .main_page {
    max-width: 90% !important;
}

/* Select */

.mat-mdc-select-panel {
    .mat-mdc-option {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 35px;
    }

    .mat-mdc-optgroup-label {
        min-height: 35px;
    }
}